<template>
  <div class='mt-3 p-2' style='width: 99%'>
    <b-modal ref='selectUser' size='lg' scrollable hide-footer title='Adicionar colaborador'>
      <b-alert show variant='danger' :class='{ hidden: !hasErrors() }'>
        {{ error }}<br />
      </b-alert>
      <div class='d-flex w-100'>
        <div class='d-flex w-100 align-items-end mr-3'>
          <base-input-text
            class='w-75 mr-1'
            block
            v-model='userSelect'
            label='E-mail do usuário'
            placeholder='Digite o e-mail do usuário'
          />

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class=' w-25'
          >
            <base-button
              block
              @click='searchUser()'
              style='height: 48px'
              class='w-100'
            >
              Adicionar
            </base-button>
          </b-overlay>
        </div>
      </div>
    </b-modal>
    <b-row class='w-100 mb-5'>
      <b-col cols='7' class='w-75'>
        <component
          :is="component"
          @set-component='showComponent'
        >
        </component>
      </b-col>
      <b-col cols='5' class='w-25'>
        <purchase-summary/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Payment from '@/components/Collaborator/Access/Payment'
import BillingDataAccess from '@/components/Collaborator/Access/BillingDataAccess'
import SelectNumberAccesses from '@/components/Collaborator/Access/SelectNumberAccesses'
import PurchaseSummary from '@/components/Collaborator/Access/PurchaseSummary'
export default {
  name: 'BuySingleAccesses',
  components: {
    Payment,
    SelectNumberAccesses,
    PurchaseSummary,
    BillingDataAccess,
  },
  data() {
    return {
      component: 'SelectNumberAccesses',
      components: {
        SelectNumberAccesses: SelectNumberAccesses
      },
      loading: false,
      userSelect: ''
    }
  },
  async mounted() {
    await this.changeCollaborators({ userMaster: this.user.id })
  },
  computed: {
    ...mapGetters('collaborator', {
      getCollaborators: 'getCollaborators',
      getCollaboratorsMeta: 'getCollaboratorsMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('user', {
      handleUserByUsername: 'handleUserByUsername'
    }),
    ...mapActions('collaborator', {
      handleCreateCollaborator: 'handleCreateCollaborator',
      handleSetCollaborator: 'handleSetCollaborator',
      handleCollaborators: 'handleCollaborators'
    }),
    showComponent(component){
      this.component = component
    },
    async changeCollaborators(payload) {
      const params = {
        ...this.getCollaboratorsMeta,
        ...payload
      }

      await this.handleCollaborators(params)
    },
    async searchUser() {
      await this.$store.dispatch('access/updateError', '')
      const { user } = await this.handleUserByUsername(this.userSelect)
      if (!user) {
        await this.$store.dispatch('access/updateError', 'Usuário não encontrado, verifique o email e tente novamente')
      }
      const collaborator = {
        userMaster: this.user.id,
        collaborator: user._id,
        planId: this.user.plan._id
      }
      this.handleSetCollaborator(collaborator)
      await this.handleCreateCollaborator()
    },
    async showModal() {
      this.$refs.selectUser.show()
      await this.$store.dispatch('access/updateError', '')
    },
    hasErrors() {
      return !!this.error
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.buySingleAccesses{
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  border-radius: 10px;
}
.btn {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
