<template>
  <div class='purchaseSummary p-3'>
    <div style='min-height: 250px'>
      <div class='my-3 mb-5'>
        <p class='numberAccesses'>
          Quantidade de acessos: <strong>{{ getAdditionalAccessQuantity }}</strong>
        </p>
        <p v-if="payment.data.installments <= 3 || this.payment.method === 'bank_slip'">
          <strong>Total: {{ formatRealCurrent(totalPrice) }}</strong>
        </p>

        <p v-else>
          <strong>Total: {{ payment.data.installments }} X de </strong>
          <strong>{{ formatRealCurrent((totalPrice + calculateInterest) / payment.data.installments) }}</strong>
        </p>
      </div>
      <footer>
        <span class='title'> Métodos de pagamento aceitos: </span>
        <div class='payment-flags'>
          <img alt='Boleto' src='../../../assets/images/pagamento/boleto.svg' />
          <img alt='Amex' src='../../../assets/images/pagamento/amex.svg' />
          <img
            alt='Dinner`s club'
            src='../../../assets/images/pagamento/dinners.svg'
          />
          <img alt='ELO' src='../../../assets/images/pagamento/elo.svg' />
          <img
            alt='Mastercard'
            src='../../../assets/images/pagamento/master.svg'
          />
          <img alt='Visa' src='../../../assets/images/pagamento/visa.svg' />
          <img class='mb-1 ml-1' style='box-shadow: rgba(100, 100, 111, 0.2) 1px 2px 2px 1px; padding: 2px 5px; border-radius: 3px' width="56" height="36" alt='PIX' src='../../../assets/images/pagamento/pix.svg' />
        </div>
        <span class='by'>
        Ambiente e transações 100% seguros por
        <a href='https://iugu.com' target='_blank'>iugu</a>
      </span>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatRealCurrent } from '@/utils/format'

export default {
  name: 'PurchaseSummary',
  props: {},

  async mounted() {
  },
  data() {
    return {
      nextComponent: 'Payment',
      loading: false,
      taxaJuros: 1.49
    }
  },
  computed: {
    ...mapGetters('checkoutAccess', {
      totalPrice: 'totalPrice',
      getAdditionalAccessQuantity: 'additionalAccessQuantity',
      interestCharged: 'interestCharged'
    }),
    ...mapGetters('checkout2', {
      payment: 'getPayment'
    }),
    calculateInterest() {
      let interestCharged = 0
      if (this.payment.data.installments > 3 && this.payment.method === 'credit_card') {
        interestCharged = Number(((this.totalPrice * Math.pow(1 + this.taxaJuros / 100, this.payment.data.installments)) - this.totalPrice).toFixed(2))
      }
      this.handleSetInterestCharged(interestCharged)
      return interestCharged
    }
  },
  methods: {
    ...mapActions('checkoutAccess', {
      handleSetInterestCharged: 'handleSetInterestCharged'
    }),
    formatRealCurrent,
    async next() {
      this.$emit('set-component', this.nextComponent)
    }
  },
  watch: {
    "payment.data.installments"(){
      if (this.payment.data.installments <= 3) {
        this.handleSetInterestCharged(0)
      }
    }
  },

}
</script>

<style lang='scss' scoped>
.purchaseSummary {
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  border-radius: 10px;
}

.loading {
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-radio {
  display: flex;
  flex-direction: row;

  .form-radio {
    padding: 5px;
    cursor: pointer !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.numberAccesses {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #022245;
}
</style>
