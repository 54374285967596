<template>
  <div class='billing-data p-3'>
    <p>Os dados informados aqui serão utilizados para o pagamento do plano e emissão de nota fiscal eletrônica</p>
    <div class='btn-radio'>
      <b-form-radio-group stacked :key='index' v-for='(item, index) in typeOptions'
                          v-model='v$.billingData.type.$model'>
        <b-form-radio class='form-radio' @change='clearFieldsSwitchBetweenLegalEntity' :value='item.key'>
          {{ item.value }}
        </b-form-radio>
      </b-form-radio-group>
    </div>

    <base-input-text
      v-model='v$.billingData.name.$model'
      type='text'
      label='Nome do usuário'
      placeholder='Digite o nome da pessoa que usará o sistema'
      block
      v-if="billingData.type === 'person'"
      :error='getError(v$.billingData.name)'
    />

    <div v-if="billingData.type === 'person'" class='d-flex justify-content-between w-100 mt-2'>
      <div class='w-50'>
        <label>CPF</label>

        <div class='d-flex mr-2'>
          <b-form-input
            id='input-cpf'
            :lazy='true'
            v-model='v$.billingData.document.$model'
            type='text'
            class='input-cpf'
            style='height: 48px;'
            placeholder='Digite o CPF'
            :state='cpfIsValid'
            aria-invalid
            v-mask="['###.###.###-##']">
          </b-form-input>
          <div>
            <b-spinner class='loading' v-if='checkingIfCPFExists' variant='primary' label='Spinning'></b-spinner>
          </div>
        </div>
        <b-form-invalid-feedback :state='cpfIsValid'>
          CPF inválido
        </b-form-invalid-feedback>
      </div>

      <div class='w-50'>
        <label>Data de Nascimento</label>

        <b-form-input
          id='input-nascimento'
          v-model='v$.billingData.birthDate.$model'
          type='date'
          class='form-control'
          style='height: 48px;'
          :state='birthDateIsValid'
          placeholder='Digite a data de Nascimento'
          aria-invalid>
        </b-form-input>

        <b-form-invalid-feedback :state='birthDateIsValid'>
          {{ getError(v$.billingData.birthDate) }}
        </b-form-invalid-feedback>
      </div>
    </div>

    <base-input-text
      class='mt-2'
      v-model='v$.billingData.companyName.$model'
      type='text'
      label='Razão Social'
      placeholder='Digite a razão social da empresa'
      block
      :error='getError(v$.billingData.companyName)'
      v-if="billingData.type === 'company'"
    />

    <div v-if="billingData.type === 'company'" class='mt-2'>
      <label>CNPJ</label>
      <div class='d-flex'>
        <b-form-input
          id='input-cnpj'
          v-model='v$.billingData.cnpj.$model'
          type='text'
          class='form-control'
          style='height: 48px;'
          placeholder='Digite o CNPJ'
          :state='CNPJIsValid'
          @keyup='buscarCNPJ($event)'
          aria-invalid
          v-mask="['##.###.###/####-##']">
        </b-form-input>
        <div>
          <b-spinner class='loading' v-if='checkingIfCNPJExists' variant='primary' label='Spinning'></b-spinner>
        </div>
      </div>
      <b-form-invalid-feedback :state='CNPJIsValid'>
        {{ getError(v$.billingData.cnpj) }}
      </b-form-invalid-feedback>
    </div>

    <base-input-text
      v-model='v$.billingData.stateRegister.$model'
      type='text'
      label='Inscrição estadual (se houver)'
      placeholder='Digite a inscrição estadual'
      block
      :error='getError(v$.billingData.stateRegister)'
      v-if="billingData.type === 'company'"
    />

    <base-input-text
      v-model='v$.billingData.cityRegister.$model'
      type='text'
      label='Inscrição municipal (se houver)'
      placeholder='Digite a inscrição municipal'
      block
      :error='getError(v$.billingData.cityRegister)'
      v-if="billingData.type === 'company'"
    />

    <div class='w-100 mt-2'>
      <label>Telefone (WhatsApp)</label>

      <div class='d-flex'>
        <b-form-input
          id='input-phone'
          v-model='v$.billingData.phone.$model'
          type='text'
          class='input-cpf'
          style='height: 48px;'
          placeholder='(11) 99245-8774'
          aria-invalid
          :state='phoneIsValid'
          @keyup='validatePhone($event)'
          v-mask="['(##) #####-####']">
        </b-form-input>
      </div>
      <b-form-invalid-feedback :state='phoneIsValid'>
        {{ getError(v$.billingData.phone) }}
      </b-form-invalid-feedback>
    </div>

    <div class='address'>
      <h2>Endereço</h2>
      <div>
        <label>CEP</label>
        <div class='d-flex'>
          <b-form-input
            id='input-cep'
            v-model='v$.billingData.address.cep.$model'
            type='text'
            class='form-control'
            style='height: 48px;'
            placeholder='Digite o cep'
            @keyup='buscarCep($event)'
            :state='cepValido'
            aria-invalid
            v-mask="'##.###-###'">
          </b-form-input>
          <div>
            <b-spinner class='loading' v-if='checkingIfCEPExists' variant='primary' label='Spinning'></b-spinner>
          </div>
        </div>
        <b-form-invalid-feedback :state='cepValido'>
          {{ getError(v$.billingData.address.cep) }}
        </b-form-invalid-feedback>
      </div>

      <div class='group'>
        <base-input-text
          v-model='v$.billingData.address.street.$model'
          type='text'
          :disabled='!cepValido'
          label='Rua'
          placeholder='Digite o nome da rua ou avenida'
          block
          :error='getError(v$.billingData.address.street)'
        />
        <base-input-text
          v-model='v$.billingData.address.number.$model'
          type='number'
          label='Número'
          :disabled='!cepValido'
          placeholder='123'
          block
          :error='getError(v$.billingData.address.number)'
        />
      </div>
      <div class='group'>
        <base-input-text
          v-model='v$.billingData.address.neighborhood.$model'
          type='text'
          label='Bairro'
          :disabled='!cepValido'
          placeholder='Digite o nome do bairro'
          block
          :error='getError(v$.billingData.address.neighborhood)'
        />
        <base-input-text
          v-model='v$.billingData.address.complement.$model'
          type='text'
          :disabled='!cepValido'
          label='Complemento (opcional)'
          placeholder='Digite o complemento'
          block
          :error='getError(v$.billingData.address.complement)'
        />
      </div>

      <div class='group'>
        <base-input-text
          v-model='v$.billingData.address.city.$model'
          type='text'
          label='Cidade'
          placeholder='Digite o nome da cidade'
          block
          :error='getError(v$.billingData.address.city)'
        />
        <base-input-select
          v-model='v$.billingData.address.state.$model'
          label='Estado'
          placeholder='Selecione um estado'
          :options='stateOptions'
          :error='getError(v$.billingData.address.state)'
          _key='key'
          _value='value'
        />
      </div>
    </div>

    <div class='d-flex mt-2'>
      <base-button danger class='w-50 mr-2' @click="$emit('set-component', previousComponent)">
        Voltar
      </base-button>

      <b-overlay
        :show='loading'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-50'
      >
        <base-button
          block
          :disabled='v$.$invalid'
          @click='next()'
        >
          Próximo
        </base-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@/utils/validators'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { helpers } from '@vuelidate/validators'
import { CPF, CNPJ } from '../../../utils/ValidateCPFCNPJ'
import { phoneValidator } from '../../../utils/phoneValidator'
import { verifyCNPJExists, verifyCPFExists } from '@/services/Search_CPF_CNPJ_CEP'

function validCNPJ() {
  return this.billingData.type === 'person' || this.CNPJIsValid
}

function validCEP() {
  return this.cepValido
}

function returnIfThePhoneIsCalid() {
  return this.phoneIsValid
}

export default {
  name: 'BillingDataAccess',
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    namePlan: { type: String }
  },
  data() {
    return {
      nextComponent: 'Payment',
      previousComponent: 'SelectNumberAccesses',
      loading: false,
      disableButtonToNotBuyOutOfTimePlan: true,
      plans: {},
      cepValido: null,
      CNPJIsValid: null,
      cpfIsValid: null,
      phoneIsValid: null,
      birthDateIsValid: null,
      checkingIfCPFExists: false,
      checkingIfCNPJExists: false,
      checkingIfCEPExists: false,
      birthDate: '',
      document: '',
      plan: {},
      typeOptions: [
        { key: 'person', value: 'Pessoa Física' },
        { key: 'company', value: 'Pessoa Jurídica' }
      ],
      stateOptions: [
        { key: 'Acre', value: 'Acre (AC)' },
        { key: 'Alagoas', value: 'Alagoas (AL)' },
        { key: 'Amapá', value: 'Amapá (AP)' },
        { key: 'Amazonas', value: 'Amazonas (AM)' },
        { key: 'Bahia', value: 'Bahia (BA)' },
        { key: 'Ceará', value: 'Ceará (CE)' },
        { key: 'Distrito Federal', value: 'Distrito Federal (DF)' },
        { key: 'Espírito Santo', value: 'Espírito Santo (ES)' },
        { key: 'Goiás', value: 'Goiás (GO)' },
        { key: 'Maranhão', value: 'Maranhão (MA)' },
        { key: 'Mato Grosso ', value: 'Mato Grosso (MT)' },
        { key: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul (MS)' },
        { key: 'Minas Gerais', value: 'Minas Gerais (MG)' },
        { key: 'Pará', value: 'Pará (PA)' },
        { key: 'Paraíba', value: 'Paraíba (PB)' },
        { key: 'Paraná', value: 'Paraná (PR)' },
        { key: 'Pernambuco', value: 'Pernambuco (PE)' },
        { key: 'Piauí', value: 'Piauí (PI)' },
        { key: 'Rio de Janeiro', value: 'Rio de Janeiro (RJ)' },
        { key: 'Rio Grande do Norte', value: 'Rio Grande do Norte (RN)' },
        { key: 'Rio Grande do Sul', value: 'Rio Grande do Sul (RS)' },
        { key: 'Rondônia', value: 'Rondônia (RO)' },
        { key: 'Roraima', value: 'Roraima (RR)' },
        { key: 'Santa Catarina', value: 'Santa Catarina (SC)' },
        { key: 'São Paulo', value: 'São Paulo (SP)' },
        { key: 'Sergipe', value: 'Sergipe (SE)' },
        { key: 'Tocantins', value: 'Tocantins (TO)' }
      ]
    }
  },
  directives: { mask },
  computed: {
    ...mapGetters('user', {
      user: 'current'
    }),
    ...mapGetters('checkoutAccess', {
      billingData: 'getBillingData'
    }),
    ...mapGetters('plans', {
      getPlans: 'getPlans'
    })
  },
  validations() {
    return {
      billingData: {
        type: {},
        name: { requiredIf: requiredIf(this.billingData.type === 'person') },
        birthDate: { requiredIf: requiredIf(this.billingData.type === 'person') },
        document: { requiredIf: requiredIf(this.billingData.type === 'person') },
        companyName: { requiredIf: requiredIf(this.billingData.type === 'company') },
        phone: { returnIfThePhoneIsCalid: helpers.withMessage('Telefone inválido', returnIfThePhoneIsCalid) },
        cnpj: { validCNPJ: helpers.withMessage('CNPJ inválido', validCNPJ) },
        stateRegister: {},
        cityRegister: {},
        address: {
          street: { required },
          number: { required },
          complement: {},
          neighborhood: { required },
          cep: { validCEP: helpers.withMessage('CEP inválido, por favor digite um cep valido para prosseguir.', validCEP) },
          city: { required },
          state: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('checkoutAccess', {
      handleSetBillingData: 'handleSetBillingData'
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async next() {
      this.loading = true
      await this.buscarCPF()
      if (!this.cpfIsValid && this.billingData.type === 'person') {
        return
      }
      this.loading = false
      this.$emit('set-component', this.nextComponent)
    },

    clearFieldsSwitchBetweenLegalEntity() {
      try {
        this.billingData.companyName = ''
        this.billingData.cnpj = ''
        this.billingData.name = ''
        this.billingData.document = ''
        this.billingData.stateRegister = ''
        this.billingData.cityRegister = ''
        this.cpfIsValid = null
        this.CNPJIsValid = null
        if (this.billingData.type === 'person') {
          this.createHtmlInputCPF()
        }
      } catch (e) {
      }
    },

    async buscarCep() {
      try {
        const cepComApenasNumeros = this.billingData.address.cep.replace('.', '').replace('-', '')
        if (cepComApenasNumeros) {
          this.cepValido = null
          if (cepComApenasNumeros.length > 7) {
            this.checkingIfCEPExists = true
            const { data: response } = await new Promise((resolve, reject) => {
              this.$api.get(`/service/cep/${cepComApenasNumeros}`)
                .then((data) => {
                  return resolve(data)
                })
                .catch((error) => {
                  return resolve(error.response)
                })
            })
            this.checkingIfCEPExists = false
            const { return: ok, message, result } = response
            this.cepValido = ok === 'OK' && !message
            this.billingData.address.street = result && result.logradouro ? result.logradouro : ''
            this.billingData.address.neighborhood = result && result.bairro ? result.bairro : ''
            this.billingData.address.city = result && result.localidade ? result.localidade : ''
            this.billingData.address.complement = result && result.complemento ? result.complemento : ''
            this.billingData.address.state = result && result.uf ? this.stateOptions.find((x) => x.value.indexOf(result.uf) > 0).key : ''
            return
          }
          this.cepValido = false
        }
      } catch (e) {
      }
    },

    async buscarCNPJ() {
      const cnpjComApenasNumeros = this.billingData.cnpj.replace(/[^\d]+/g, '')
      if (cnpjComApenasNumeros) {
        this.CNPJIsValid = null
        if (CNPJ(cnpjComApenasNumeros)) {
          this.checkingIfCNPJExists = true
          const { data: response } = await verifyCNPJExists(cnpjComApenasNumeros)
          this.checkingIfCNPJExists = false;
          const { cnpjExists } = response
          this.CNPJIsValid = cnpjExists
          if (!this.CNPJIsValid) {
            this.$bvToast.toast('CNPJ inválido', {
              title: 'Aviso',
              variant: 'success',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
          }
          return
        }
        this.CNPJIsValid = false

      }
    },

    async buscarCPF() {
      const cpfComApenasNumeros = this.billingData.document.replace(/[^\d]+/g, '')
      if (cpfComApenasNumeros && this.billingData.birthDate) {
        this.cpfIsValid = null
        if (CPF(cpfComApenasNumeros)) {
          this.checkingIfCPFExists = true
          //const { data: response } = await verifyCPFExists(cpfComApenasNumeros, this.billingData.birthDate)
          this.checkingIfCPFExists = false
          //const { cpfExists } = response
          this.cpfIsValid = true
          if (!this.cpfIsValid) {
            this.$bvToast.toast(message, {
              title: 'Aviso',
              variant: 'success',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
          }
          return
        }
        this.cpfIsValid = false
      }
    },

    validatePhone() {
      try {
        const phone = this.billingData.phone.replace(/[^\d]/g, '')
        if(phone) {
          this.phoneIsValid = phoneValidator(phone) && phone.length >= 10
        }
      } catch (e) {
      }
    },

    getError(value) {
      if (value.$errors.length > 0) {
        return value.$errors[0].$message
      }
      return ''
    },
    createHtmlInputCPF() {
      document.getElementById('input-cpf').addEventListener('keyup', async (e) => {
        this.billingData.document = e.target.value
        await this.buscarCPF()
      })
    }
  },
  async mounted() {
    this.createHtmlInputCPF()
  },
  created() {
    try {
      const breadcrumb = {
        page: 'Comprar acessos',
        subPage: {
          name: 'Comprar acessos',
          active: false
        },
        tabs: [
          {
            name: 'Dados do faturamento',
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
      this.buscarCPF()
      this.buscarCNPJ()
      this.buscarCep()
      this.validatePhone()
      if (this.user && this.user.username) {
        this.billingData.email = this.user.username
      }
    } catch (e) {

    }
  }
}
</script>

<style lang='scss' scoped>

.loading {
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-radio {
  display: flex;
  flex-direction: row;

  .form-radio {
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer !important;
  }
}

.billing-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  border-radius: 10px;
  label {
    font-size: 18px;
    font-weight: 700;
  }

  .use-account-data {
    span {
      font-size: 18px;
    }

    .buttons {
      display: flex;
      gap: 8px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    .group {
      display: grid;
      grid-template-columns:  60% 38%;
      gap: 2%;
      margin-top: 10px;
      align-items: end;
    }
  }
}
</style>
