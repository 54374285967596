<template>
  <div class='payment'>
    <b-card v-if='chargeMadeSuccessfully' class='credit_card-form'>
      <div class='d-flex align-items-center'>
        <p>Compra efetuada com sucesso!</p>
        <b-icon icon='check-circle' class='mb-3 ml-2' variant='success' font-scale='1.5'></b-icon>

      </div>
      <router-link
        active-class='link-clicked'
        class='subMenuItem'
        :to="{ name: 'Colaboradores' }"
      >

        <span class='label'>Ir para meus colaboradores</span>
      </router-link>
    </b-card>

    <b-card v-else class='credit_card-form'>
      <div style='min-height: 250px' class='d-flex align-content-center' v-if='loading'>
        <p class='mt-1' v-if='tentativa === 1'>Aguarde enquanto estamos tentando processar seu pagamento.</p>
        <p class='mt-1' v-else-if='tentativa === 2'>Estamos tendo lentidão ao processar pagamento, por favor, aguarde
          mais um
          pouco.</p>
        <p class='mt-1' v-else>Por favor, aguarde mais um pouco</p>
        <b-spinner class='loading-spinner ml-2 ' variant='primary' label='Spinning'></b-spinner>
      </div>

      <div v-else>
        <div class='mb-3'>
          <label class='mb-2'>Selecione a forma de pagamento</label>
          <base-input-radio
            v-model='payment.method'
            :options='methodsOptions'
          />

          <p class='mt-2'>
            <template v-if="payment.method == 'credit_card'">
              A liberação dos acessos à plataforma HOFácil Web acontece de maneira automática a partir do retorno
              positivo
              da compensação financeira advinda da instituição financeira utilizada para realizar o pagamento.
              O pagamento via cartão de crédito pode levar até 5 minutos para ser analisado e compensado.
            </template>
            <template v-else-if="payment.method == 'bank_slip'">
              <p>
                <strong>Quando paga via PIX:</strong> a liberação do acesso à plataforma HO Fácil Web acontece em até 15 minutos.
              </p>

              <p>
                <strong>Quando paga via boleto bancário:</strong> a liberação do acesso à plataforma HO Fácil Web acontece de maneira
                automática a partir do retorno positivo da compensação financeira advinda da instituição financeira utilizada para
                realizar o pagamento. O pagamento via boleto bancário leva de 24 horas a 72 horas contadas em dias úteis. Sendo o
                pagamento feito por boleto bancário, o acesso à plataforma HO Fácil poderá levar até 72h contadas a partir do pagamento
                do boleto. Caso o pagamento do boleto seja feito por agendamento, o prazo começará a ser contado na data de pagamento do agendamento.
              </p>
            </template>
          </p>
        </div>

        <div v-if="payment.method === 'credit_card'">
          <base-alert2
            v-if='alert.open'
            class='alert'
            :type='alert.type'
            :message='alert.message'
            @close='alert.open = false'
          />
          <div class='d-flex align-items-end'>
            <base-input-text
              class='mr-2'
              v-model='v$.payment.data.first_name.$model'
              type='text'
              block
              label='Nome impresso no cartão'
              placeholder='Nome impresso no cartão'
              :error='getError(v$.payment.data.first_name)'
            />

            <base-input-text
              v-model='v$.payment.data.last_name.$model'
              type='text'
              block
              class='mt-4'
              label='Sobrenome impresso no cartão'
              placeholder='Sobrenome impresso no cartão'
              :error='getError(v$.payment.data.last_name)'
            />
          </div>

          <base-input-text
            v-model='v$.payment.data.number.$model'
            type='text'
            label='Número do Cartão'
            class='mt-4'
            placeholder='0000 0000 0000 0000'
            v-mask="'#### #### #### ####'"
            :error='getError(v$.payment.data.number)'
          />

          <div class='validity mt-4'>
            <base-input-select
              v-model='v$.payment.data.month.$model'
              type='text'
              label='Mês'
              placeholder='Mês'
              :options='monthOptions'
            />

            <base-input-select
              v-model='v$.payment.data.year.$model'
              type='text'
              label='Ano'
              placeholder='Ano'
              :options='yearOptions()'
            />

            <base-input-text
              v-model='v$.payment.data.cvv.$model'
              type='text'
              label='Código de Segurança'
              placeholder='000'
              v-mask="['###', '####', '#####']"
            >
              <template #icon-label>
                <base-tooltip>
                  <template #body>
                    <i class='fa fa-info-circle' />
                  </template>
                  <template v-slot:message>
                    O código de segurança fica impresso no verso do cartão de crédito.
                  </template>
                </base-tooltip>
              </template>
            </base-input-text>
            <div class='cc-back'>
              <img src='../../../assets/images/cc-icon.png' />
            </div>
          </div>

          <div class='mt-4'>
            <label>Quatidade de parcelas</label>
            <b-form-select

              style='height: 50px'
              v-model='v$.payment.data.installments.$model'
              type='text'
              :options='installmentsOptions'
              :error='getError(v$.payment.data.installments)'
              _key='value'
              _value='text'>
            </b-form-select>
          </div>
        </div>

        <div class='buttons w-100 d-flex justify-content-end mt-4'>
          <base-button danger class=' w-50' @click="$emit('set-component', previousComponent)">
            Voltar
          </base-button>
          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block w-50'
          >
            <base-button :disabled='loading || !canNext()' @click='handleSubmitPaymentMethod' class='w-100'>
              <span class='ml-2'>Finalizar compra</span>
            </base-button>
          </b-overlay>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@/utils/validators'
import { getYear } from 'date-fns'
import { mask } from 'vue-the-mask'
import IUGU from '@/services/iugu'

export default {
  name: 'Payment',
  components: {},
  directives: { mask },
  setup() {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapGetters('checkout2', {
      payment: 'getPayment',
      isCreditCard: 'getIsCreditCard',
      alert: 'getAlertDetails'

    })
  },
  data() {
    return {
      token: '',
      chargeMadeSuccessfully: false,
      tentativa: 0,
      loading: false,
      previousComponent: 'BillingDataAccess',
      methodsOptions: [
        { key: 'credit_card', value: 'Cartão de crédito' },
        { key: 'bank_slip', value: 'Boleto bancário e PIX' }
      ],
      monthOptions: [
        { _id: '1', value: '1 - Janeiro' },
        { _id: '2', value: '2 - Fevereiro' },
        { _id: '3', value: '3 - Março' },
        { _id: '4', value: '4 - Abril' },
        { _id: '5', value: '5 - Maio' },
        { _id: '6', value: '6 - Junho' },
        { _id: '7', value: '7 - Julho' },
        { _id: '8', value: '8 - Agosto' },
        { _id: '9', value: '9 - Setembro' },
        { _id: '10', value: '10 - Outubro' },
        { _id: '11', value: '11 - Novembro' },
        { _id: '12', value: '12 - Dezembro' }
      ],
      installmentsOptions: [
        { value: 1, text: '1x sem juros' },
        { value: 2, text: '2x sem juros' },
        { value: 3, text: '3x sem juros' },
        { value: 4, text: '4x com juros' },
        { value: 5, text: '5x com juros' },
        { value: 6, text: '6x com juros' },
        { value: 7, text: '7x com juros' },
        { value: 8, text: '8x com juros' },
        { value: 9, text: '9x com juros' },
        { value: 10, text: '10x com juros' },
        { value: 11, text: '11x com juros' },
        { value: 12, text: '12x com juros' }
      ]
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Comprar acessos',
      subPage: {
        name: 'Comprar acessos',
        active: false
      },
      tabs: [
        {
          name: 'Forma de pagamento',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    IUGU.setup()
    this.payment.method = 'credit_card'
  },
  validations() {
    return {
      payment: {
        method: { required },
        data: {
          first_name: { required },
          last_name: { required },
          number: { required, minLength: minLength(17) },
          month: { required },
          year: { required },
          cvv: { required, minLength: minLength(3), maxLength: maxLength(5) },
          installments: {}
        }
      }
    }
  },
  methods: {
    ...mapActions('creditCard', {
      handleCreatePaymentMethods: 'handleCreatePaymentMethods'
    }),
    ...mapActions('checkout2', {
      handleGetTokenCreditCard: 'handleGetTokenCreditCard',
      handleSetAlertDetails: 'handleSetAlertDetails'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('checkoutAccess', {
      handleSetToken: 'handleSetToken',
      handleSubmitCheckout: 'handleSubmitCheckout'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async handleSubmitPaymentMethod() {
      try {
        this.loading = true
        if(this.payment.method === 'credit_card') {
          const response = await this.handleGetTokenCreditCard()
          if (response.error || response.token.error) {
            const message = response.error || response.token.error
            this.$bvToast.toast(message, {
              title: 'Aviso',
              variant: 'danger',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
            this.loading = false
            return
          }
          this.handleSetToken(response.token)
        }
        this.tentativa++

        const { method, data } = this.payment
        const responseSubmitCheckout = await this.handleSubmitCheckout({ method, installments: data.installments })

        if (responseSubmitCheckout.errors) {
          this.loading = false
          const errorRaw = responseSubmitCheckout.errors
          let error = errorRaw || 'Dados inválidos, verifique e tente novamente!'

          if (errorRaw['payer.address.zip_code'] || errorRaw['zip_code']) {
            error = 'CEP inválido, verifique e tente novamente!'
          }

          if (errorRaw['payer.cpf_cnpj'] || errorRaw['cpf_cnpj']) {
            error = 'CPF/CNPJ inválido, verifique e tente novamente!'
          }
          if (errorRaw['plan_identifier']) {
            error = 'Plano inválido, verifique e tente novamente!'
          }
          if (errorRaw['token is invalid']) {
            error = 'token is invalid'
          }
          if (errorRaw['token'] || errorRaw === 'Esse token já foi usado.' || errorRaw === 'This token has already been used') {
            error = 'Por favor preencha os dados do cartão novamente'
          }

          this.handleSetAlertDetails({
            open: true,
            type: 'danger',
            message: error
          })
        } else {
          const {chargeMadeSuccessfully} = responseSubmitCheckout
          this.chargeMadeSuccessfully = chargeMadeSuccessfully
          this.loading = false

        }
      } catch (e) {
        if (this.tentativa < 3) {
          setTimeout(async () => {
            await this.handleSubmitPaymentMethod()
          }, 22500)
        } else {
          this.tentativa = 0
          this.handleSetAlertDetails({
            open: true,
            type: 'danger',
            message: 'Não foi possível processar seu pagamento, tente novamente mais tarde!!!'
          })
          this.loading = false
        }
      } finally {

      }
    },
    async submit() {
      try {
        this.loading = true
        const { sucess, errors } = await this.handleCreatePaymentMethods({ tokenCard: this.token })
        if (errors) {
          this.$bvToast.toast(errors, {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          return
        }

        this.$bvToast.toast('Alteração efetuada com sucesso!', {
          title: 'Aviso',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })

        setTimeout(() => {
          this.$emit('close')
        }, 2000)
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    canNext() {
      if (!this.payment.method) return false

      if (this.isCreditCard) {
        if (this.v$.payment.data.$invalid) return false
      }

      return true
    },
    yearOptions() {
      const now = new Date()
      const currentYear = getYear(now)
      const yearOptions = []
      const CURRENT_YEAR_ADD_15_YEARS = currentYear + 15

      for (let year = currentYear; year <= CURRENT_YEAR_ADD_15_YEARS; year += 1) {
        yearOptions.push({
          _id: year,
          value: year
        })
      }
      return yearOptions
    },
    getError(value) {
      if (value.$errors.length > 0) {
        return value.$errors[0].$message
      }
      return ''
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.loading-spinner {
  width: 2.5rem;
  height: 2.5rem;
}

.payment {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .credit_card-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .group, .validity {
      display: grid;
      gap: 16px;
    }

    .group {
      grid-template-columns: 1fr 1fr;
      @media only screen and (max-width: 720px) {
        grid-template-columns: 1fr;
      }
    }

    label {
      font-size: 18px;
      font-weight: 700;
    }

    .validity {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .cc-back {
        display: flex;
        align-items: flex-end;
        // justify-content: center;
        padding: 5px;

        img {
          width: 40px;
          height: auto;
        }
      }
    }
  }

  .promotion {
    a {
      color: var(--blue);

      &:hover {
        text-decoration: underline;
      }
    }

    .check-code {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    gap: 5px;
  }
}
</style>
