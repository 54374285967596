<template>
  <div class='selectNumberAccesses p-3'>
    <div style='min-height: 250px'>
      <div class='my-3' v-if='numberExistingUsers < maximumAmountOfHitsCanBePurchased'>
        <p class='numberAccesses'>
          Selecione a quantidade adicional de acessos que você pretende contratar para seus colaboradores (descontos a
          partir de 30%):
        </p>

        <div class='group'>
          <b-form-select
            v-model='additionalAccessQuantity'
            :options='employees'
            class='mb-3'
            style='height: 50px'
            value-field='key'
            text-field='value'
            @change='calculateTotal()'
          >
            <template #first>
              <b-form-select-option :value='0' disabled>Selecione a quantidade de colaboradores</b-form-select-option>
            </template>
          </b-form-select>

          <div class='p-2' v-if='additionalAccessQuantity > 0'>
            <p>
              <strong>
                Atualmente você possui {{ numberExistingUsers }} {{ numberExistingUsers > 1 ? 'acessos' : 'acesso' }}
                {{ numberExistingUsers > 1 ? 'adicionais' : 'adicional' }}.
                Você está comprando {{ additionalAccessQuantity }}
                {{ additionalAccessQuantity > 1 ? 'acessos' : 'acesso' }}
                {{ additionalAccessQuantity > 1 ? 'adicionais' : 'adicional' }}.
                Dessa forma você terá {{ (numberExistingUsers + additionalAccessQuantity) }}
                {{ (numberExistingUsers + additionalAccessQuantity) > 1 ? 'acessos' : 'acesso' }}.
                O seu desconto aplicável para a compra de {{ additionalAccessQuantity }}
                {{ additionalAccessQuantity > 1 ? 'acessos' : 'acesso' }}
                {{ numberExistingUsers > 1 ? 'adicionais' : 'adicional' }} é de
                {{ percentage((additionalAccessQuantity + numberExistingUsers)) }}%.
              </strong>
            </p>
            <p>
              <strong>
                Preço pago no plano:
                <span>
                  {{
                    formatRealCurrent(Number(paidReferenceValue))
                  }}
                </span>
              </strong><br />

              <strong>
                {{ additionalAccessQuantity > 1 ? 'Preço dos acessos adicionais:' : 'Preço do acesso adicional:' }}
                <span>
                  {{
                    formatRealCurrent(Number(totalPrice))
                  }}
                </span>
              </strong><br />

              <strong>
                Total :
                <span>
                  {{
                    formatRealCurrent(Number(totalPrice))
                  }}
                </span>
              </strong><br />
            </p>
          </div>
        </div>
      </div>

      <div v-else>
        O plano {{this.user.plan.plan === 'AN1HO' ? 'Anual' : 'Promocional'}}
        adquiro no dia {{ formatDate(this.user.plan.createdAt)}}
        pode comprar até {{maximumAmountOfHitsCanBePurchased}} acessos.

        <h6 class='mt-2'><strong>Acessos comprados:</strong></h6>

        <div v-for='(item, i) in listPurchasedHits' :key="i">
          <p style='border-top: 1px solid #ccc'>
            <strong>Data da compra: </strong> <span>{{formatDate(item.createdAt)}}</span><br/>
            <strong>Acessos comprados: </strong> <span>{{item.amountPurchased}}</span><br/>
            <strong>Acessos restante: </strong> <span>{{item.remainingAmount}}</span><br/>
            <strong>Acessar fatura: </strong> <a :href='item.invoiceUrl' target='_blank'>Clique aqui para acessar</a>
          </p>
        </div>
        <h6 class='mt-2'>
          <strong>Total de cessos comprados: </strong>
          <span>{{listPurchasedHits.reduce((acumulator, access) => acumulator + access.amountPurchased, 0)}}</span>
        </h6>
      </div>
    </div>

    <b-row>
      <b-col cols='12'>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-100'
          v-if='numberExistingUsers < maximumAmountOfHitsCanBePurchased'
        >
          <base-button
            :disabled='additionalAccessQuantity <= 0'
            block
            @click='next()'
          >
            Próximo
          </base-button>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { formatRealCurrent } from '@/utils/format'
import { required } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'
import format from 'date-fns/esm/format/index'

export default {
  name: 'SelectNumberAccesses',
  setup() {
    return { v$: useVuelidate() }
  },
  props: {},

  async mounted() {
    this.planId = this.$route.params.planId
    await this.purchasedHits(this.planId)
    const { paidReferenceValue } = await this.handleAmountPaidPlan(this.planId)
    const { numberExistingUsers } = await this.handleAmountAccessCollaboratorPaid(this.planId)
    this.handleSetNumberExistingUsers(numberExistingUsers)
    this.handleSetPaidReferenceValue(paidReferenceValue)
    this.additionalAccessQuantity = this.getAdditionalAccessQuantity
    this.generateOptionsNumberEmployees()
    await this.calculateTotal()
    this.handleSetPlanId(this.planId)
  },
  created() {
    const breadcrumb = {
      page: 'Comprar acessos',
      subPage: {
        name: 'Comprar acessos',
        active: false
      },
      tabs: [
        {
          name: 'Selecionar quantidade de acessos',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  data() {
    return {
      nextComponent: 'BillingDataAccess',
      planId: '',
      loading: false,
      access: [],
      additionalUserDiscount: 0.025,
      baseDiscount: 0.275,
      maximumDiscount: 0.5,
      employees: [],
      diasEmUmAno: 365,
      additionalAccessQuantity: 0,
      maximumAmountOfHitsCanBePurchased: 10,
      listPurchasedHits: []
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'current'
    }),
    ...mapGetters('checkoutAccess', {
      paidReferenceValue: 'paidReferenceValue',
      getAdditionalAccessQuantity: 'additionalAccessQuantity',
      numberExistingUsers: 'numberExistingUsers',
      totalPrice: 'totalPrice'
    })
  },
  validations() {
    return {
      additionalAccessQuantity: {}
    }
  },
  methods: {
    ...mapActions('userPlans', {
      handleAmountPaidPlan: 'handleAmountPaidPlan'
    }),
    ...mapActions('checkoutAccess', {
      handleSetPaidReferenceValue: 'handleSetPaidReferenceValue',
      handleSetNumberExistingUsers: 'handleSetNumberExistingUsers',
      handleSetTotalPrice: 'handleSetTotalPrice',
      handleSetadditionalAccessQuantity: 'handleSetadditionalAccessQuantity',
      handleSetPlanId: 'handleSetPlanId'
    }),
    ...mapActions('acessCollaborator', {
      handleAmountAccessCollaboratorPaid: 'handleAmountAccessCollaboratorPaid',
      handleAmountAccessPlan: 'handleAmountAccessPlan',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async purchasedHits() {
      try {
        const {accessCollaborator} = await this.handleAmountAccessPlan(this.planId)
        this.listPurchasedHits = accessCollaborator
      } catch (e) {

      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    formatRealCurrent,
    generateOptionsNumberEmployees() {
      for (let employee = 1; employee <= (this.maximumAmountOfHitsCanBePurchased - this.numberExistingUsers); employee++) {
        this.employees.push({
          key: employee,
          value: `${employee} ${employee > 1 ? 'colaboradores adicionais ' : 'colaborador adicional'} `
        })
      }
    },

    percentage(quantidadeColaboradores) {
      switch (quantidadeColaboradores) {
        case 1: {
          return 30
        }
        case 2: {
          return 32.5
        }
        case 3: {
          return 35
        }
        case 4: {
          return 37.5
        }
        case 5: {
          return 40
        }
        case 6: {
          return 42.5
        }
        case 7: {
          return 45
        }
        case 8: {
          return 47.5
        }
        default: {
          return 50
        }
      }
    },

    async calculateTotal() {
      this.access = []

      this.handleSetadditionalAccessQuantity(this.additionalAccessQuantity)
      for (let i = 1; i <= this.additionalAccessQuantity; i++) {
        const percentage = this.percentage(i + (this.maximumAmountOfHitsCanBePurchased - this.numberExistingUsers))
        this.access.push(
          {
            quantidadeAcesso: i,
            discount: percentage.toString().replace('.', ',')
          }
        )
      }

      const quantidadeDiasRestanteDataVencimentoPlano = this.calcularQuantidadeDiasRestanteDataVencimentoPlano(new Date(this.user.plan.dueDate), new Date(this.user.currentDate))
      const valorReferanciaParaComprarAcessos = this.calculoValorReferanciaParaComprarAcessos(quantidadeDiasRestanteDataVencimentoPlano)

      const total = Number(this.calcularTotal(valorReferanciaParaComprarAcessos))
      this.handleSetTotalPrice(total)
    },

    calculoValorReferanciaParaComprarAcessos(quantidadeDias) {
      const precoPorDia = Number(this.paidReferenceValue) / Number(this.diasEmUmAno)
      return precoPorDia * quantidadeDias
    },

    calcularQuantidadeDiasRestanteDataVencimentoPlano(dataValidadeUsuarioMaster, dataCompraUsuarioSubordinado) {
      let diasRestanteVencimentoPlano = Math.floor(moment.duration(moment(dataValidadeUsuarioMaster).diff(moment(dataCompraUsuarioSubordinado))).asDays())
      if (diasRestanteVencimentoPlano > this.diasEmUmAno) {
        diasRestanteVencimentoPlano = this.diasEmUmAno
      }
      return diasRestanteVencimentoPlano
    },

    calcularTotal(precoNoCheckout) {
      this.additionalUserDiscount = 0.025
      this.baseDiscount = 0.275
      this.additionalUserDiscount *= (this.numberExistingUsers + this.additionalAccessQuantity)
      this.baseDiscount += this.additionalUserDiscount
      if (this.baseDiscount > this.maximumDiscount) {
        this.baseDiscount = this.maximumDiscount
      }
      let total = 0
      for (let i = 1; i <= this.additionalAccessQuantity; i++) {
        const precoPorAcesso = Number(precoNoCheckout) * (1 - this.baseDiscount)
        total += Number(precoPorAcesso)
      }
      return total
    },

    async next() {
      this.$emit('set-component', this.nextComponent)
    }
  },
  watch: {}

}
</script>

<style lang='scss' scoped>
.selectNumberAccesses {
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  border-radius: 10px;
}

.loading {
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-radio {
  display: flex;
  flex-direction: row;

  .form-radio {
    padding: 5px;
    cursor: pointer !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .hits_value {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #04A8F3;
    }

    .hits_description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: #262626;
    }
  }
}

.hits_select {
  border: 1px solid #04A8F3;
  padding: 5px;
  border-radius: 5px
}

.hits_no_select {
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 5px
}

.billing-data {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title_options {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #022245;
  }

  label {
    font-size: 18px;
    font-weight: 700;
  }

  .use-account-data {
    span {
      font-size: 18px;
    }

    .buttons {
      display: flex;
      gap: 8px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    .group {
      display: grid;
      grid-template-columns:  60% 38%;
      gap: 2%;
    }
  }
}

.numberAccesses {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #022245;
}
</style>
